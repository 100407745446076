/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { deleteWidget } from "reducers/app";
import axios from "axios";
import ReactMapGL, {
	Source,
	Layer,
	Popup as MapPopup,
} from "react-map-gl";
import mapStyleEmptyJSON from 'assets/mapstyles/style_osrd_empty.json'
import mapStyleBlankJSON from 'assets/mapstyles/style_osrd_blank.json'
import mapStyleLightJSON from 'assets/mapstyles/style_osrd_light.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'


import PRView from 'components/Map/PRView'
import Spinner from 'react-bootstrap/Spinner';
import { DEFAULT_VIEWPORT, MAP_URL } from 'common/Map/const'
import { transformRequest, secondsToDhms } from 'utils/helpers'

import { displayColorLegend } from "./utils";

function MapWidget(props) {
	const dispatch = useDispatch()
	const [loadingError, setLoadingError] = useState(false);
	const [showPopup, togglePopup] = useState(false)
	const [featureInfoHover, setFeatureInfoHover] = useState(undefined)
	const [widgetData, setWidgetData] = useState(undefined);
	const [showIncidentsLayer, setShowIncidentsLayer] = useState("none");
	const [showIncidents, setShowIncidents] = useState(false);
	const [loader, setLoader] = useState(false)
	const [hoverInfo, setHoverInfo] = useState(null);
	const [showPrsLayer, setShowPrsLayer] = useState("visible");
	const [showSegmentsLayer, setShowSegmentsLayer] = useState("visible");
	const [showSegments, setShowSegments] = useState(true);
	const [showPrs, setShowPrs] = useState(true);
	const [color, setColor] = useState({
		Q1: '#cd0037',
		Q2: '#0088ce',
		Q3: '#00B050',
		segment: '#82be00',
		grey: '#c3beb4'
	});

	const [viewport, setViewport] = useState({
		...DEFAULT_VIEWPORT,
		transformRequest: (url, resourceType) => transformRequest(url, resourceType, MAP_URL),
	});

	const togglePRs = () => {
		if (showPrsLayer === "visible") {
			setShowPrsLayer("none");
		} else {
			setShowPrsLayer("visible");
		}
	};

	const toggleSegments = () => {
		if (showSegmentsLayer === "visible") {
			setShowSegmentsLayer("none");
		} else {
			setShowSegmentsLayer("visible");
		}
	};

	useEffect(() => {
		setLoader(true)
		setLoadingError(false)
		axios({
			method: 'get',
			url: props.url,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			}
		})
			.then(function (response) {
				setWidgetData(response.data)
				setShowIncidentsLayer("visible")
				setShowIncidents(true)
				setShowPrs(true);
				setShowSegments(true)
			})
			.catch(function (error) {
				setLoadingError(true)
			})
			.then(function () {
				setLoader(false)
			});

	}, []);


	const applyMapStyle = () => {
		var result = props.widget.parameters.filter(obj => {
			return obj.slug === 'map_background'
		})
		if (result[0].value === 'Sans fond') {
			return mapStyleBlankJSON
		} else if (result[0].value === 'Enrichi') {
			return mapStyleLightJSON
		} else return mapStyleEmptyJSON
	}

	const applySegmentation = () => {
		var result = props.widget.parameters.filter(obj => {
			return obj.slug === 'spatial_scale'
		})
		if (result[0].value === '1 km') {
			return "pm_sig_segments_un_km"
		} else if (result[0].value === '5 km') {
			return "pm_sig_segments_cinq_km"
		} else if (result[0].value === '10 km') {
			return "pm_sig_segments_dix_km"
		} else return "pm_sig_segments_strategique"
	}

	function roundNumber(value) {
		return Math.round(value * 100) / 100
	}


	function popupContent() {
		if (featureInfoHover.features[0].layer.id === 'mtbfLayer') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>MTBF : {featureInfoHover?.features[0]?.properties?.MTBF}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Classe stratégique : {featureInfoHover?.features[0]?.properties?.classe_strategique}</strong></div>
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_traffic}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'prMainLayer') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Libellé du PR : {featureInfoHover.features[0].properties.libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>CI : {featureInfoHover?.features[0]?.properties?.ci}</strong></div>
						<div><strong>CH : {featureInfoHover?.features[0]?.properties?.ch}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'lineLayer' || featureInfoHover.features[0].layer.id === 'segmentMainLayerAll' || featureInfoHover.features[0].layer.id === 'lineLayerClass' || featureInfoHover.features[0].layer.id === 'lineLayerClassOnly') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Sans incident MTBF</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Classe stratégique : {featureInfoHover?.features[0]?.properties?.classe_strategique}</strong></div>
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_traffic}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'segmentMainLayerHighlighted') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Classe stratégique : {featureInfoHover.features[0].properties.classe_strategique}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_traffic}</strong></div>
					</div>
				</>
			)
		}
	}

	const mtbfLayerStyle = {
		beforeId: "prMainLayer",
		id: 'mtbfLayer',
		type: "line",
		paint: {
			'line-width': 4,
			"line-color": [
				"case",
				[
					"all",
					[
						"<=",
						["get", "metric"],
						widgetData?.scale[0],
					],
				],
				color.Q1,
				[
					"all",
					[
						">",
						["get", "metric"],
						widgetData?.scale[0],
					],
					[
						"<",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q2,
				[
					"all",
					[
						">=",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q3,
				"#c3beb4",
			],
		}
	};

	const mtbfLayerStyleHighlighted = {
		beforeId: "prMainLayer",
		id: 'mtbfLayerStyleHighlighted',
		type: "line",
		paint: {
			'line-width': 7,
			"line-color": [
				"case",
				[
					"all",
					[
						"<=",
						["get", "metric"],
						widgetData?.scale[0],
					],
				],
				color.Q1,
				[
					"all",
					[
						">",
						["get", "metric"],
						widgetData?.scale[0],
					],
					[
						"<",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q2,
				[
					"all",
					[
						">=",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q3,
				"#c3beb4",
			],
		}
	};

	const segmentMainLayer = {
		beforeId: "mtbfLayer",
		id: "segmentMainLayer",
		type: "line",
		paint: {
			"line-color": color.grey,
			"line-width": 4
		}
	}

	const segmentMainLayerHighlighted = {
		beforeId: "mtbfLayer",
		id: "segmentMainLayerHighlighted",
		type: "line",
		paint: {
			"line-color": color.grey,
			"line-width": 8
		}
	}

	const segmentMainLayerAll = {
		beforeId: "mtbfLayer",
		id: "segmentMainLayerAll",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 4
		}
	}

	const segmentMainLayerHighlightedAll = {
		beforeId: "mtbfLayer",
		id: "segmentMainLayerHighlightedAll",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 8
		}
	}

	const lineLayer = {
		beforeId: "mtbfLayer",
		id: "lineLayer",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 4
		}
	}

	const lineLayerHighlighted = {
		beforeId: "mtbfLayer",
		id: "lineLayerHighlighted",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 8
		}
	}

	const lineLayerClass = {
		beforeId: "mtbfLayer",
		id: "lineLayerClass",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 4
		}
	}

	const lineLayerHighlightedClass = {
		beforeId: "mtbfLayer",
		id: "lineLayerHighlightedClass",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 8
		}
	}

	const lineLayerClassOnly = {
		beforeId: "mtbfLayer",
		id: "lineLayerClassOnly",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 4
		}
	}

	const lineLayerHighlightedClassOnly = {
		beforeId: "mtbfLayer",
		id: "lineLayerHighlightedClassOnly",
		type: "line",
		paint: {
			"line-color": color.segment,
			"line-width": 8
		}
	}

	const deleteWidgetFromDashboard = () => {
		dispatch(deleteWidget(props.dashboardId, props.widget.id));
	};

	const onFeatureClick = e => {
		if (e?.features?.length > 0) {
			setFeatureInfoHover(e)
			togglePopup(true)
		}
	}
	const selectedMtbfFeature = (hoverInfo && hoverInfo.mtbfFeature) || '';

	const filter = useMemo(() => ['in', 'id', selectedMtbfFeature], [selectedMtbfFeature]);
	const filter2 = () => ["in", ['get', 'classe_strategique'], ['literal', props.widget.parameters[0].value]];
	const onHover = useCallback(event => {
		const mtbfFeature = event.features && event.features[0];
		setHoverInfo({
			mtbfFeature: mtbfFeature && mtbfFeature.properties.id
		});
	}, []);
	return (
		<>
			<div style={{ width: '100%', height: '100%' }}>
				{loader && (<div className='loader-container'>
					<Spinner animation="border" variant="info" />
				</div>)}
				<ReactMapGL
					{...viewport}
					style={{ cursor: 'pointer' }}
					width="100%"
					height="100%"
					mapStyle={applyMapStyle()}
					onViewportChange={setViewport}
					onClick={onFeatureClick}
					onHover={onHover}
					attributionControl={false}
					clickRadius={2}
					interactiveLayerIds={['mtbfLayer', 'prMainLayer', 'segmentMainLayer', 'lineLayer', 'segmentMainLayerAll', 'segmentMainLayerHighlighted', 'lineLayerClass', 'lineLayerClassOnly']}
				>
					{showIncidents && (
						<Source type="geojson" data={widgetData?.data}>
							<Layer {...mtbfLayerStyle} layout={{ visibility: showIncidentsLayer }} />
							<Layer {...mtbfLayerStyleHighlighted} layout={{ visibility: showIncidentsLayer }} filter={filter} />
						</Source>
					)}

					{/* {showSegments && (<SegmentationView showSegmentsLayer={showSegmentsLayer}/>)} */}
					{showSegments && (
						<Source
							type="vector"
							url={`${MAP_URL}/chartis/v2/layer/${applySegmentation()}/mvt/geo/`}
							source-layer={OBJECTS_LAYERS.tivGeo}
						>
							<Layer
								{...segmentMainLayer}
								layout={{ visibility: showSegmentsLayer }}
								source-layer={applySegmentation()}
							/>
							<Layer
								{...segmentMainLayerHighlighted}
								layout={{ visibility: showSegmentsLayer }}
								source-layer={applySegmentation()}
								filter={filter}
							/>
						</Source>
					)}
					<Source
						type="vector"
						url={`${MAP_URL}/chartis/v2/layer/${applySegmentation()}/mvt/geo/`}
						source-layer={OBJECTS_LAYERS.tivGeo}
					>
						<Layer
							{...segmentMainLayerAll}
							layout={{ visibility: props.widget.parameters[0].value === 'Tous' && props.widget.parameters[1].slug !== 'lines' ? "visible" : "none" }}
							source-layer={applySegmentation()}
						/>
						<Layer
							{...segmentMainLayerHighlightedAll}
							layout={{ visibility: props.widget.parameters[0].value === 'Tous' && props.widget.parameters[1].slug !== 'lines' ? "visible" : "none" }}
							source-layer={applySegmentation()}
							filter={filter}
						/>
					</Source>
					<Source
						type="vector"
						url={`${MAP_URL}/chartis/v2/layer/${applySegmentation()}/mvt/geo/?codes_lignes__overlaps=${props.widget.parameters[1].value.replaceAll(/\s/g, '')}`}
						source-layer={OBJECTS_LAYERS.tivGeo}
					>
						<Layer
							{...lineLayer}
							layout={{ visibility: props.widget.parameters[1].slug === 'lines' && props.widget.parameters[0].value === 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
						/>
						<Layer
							{...lineLayerHighlighted}
							layout={{ visibility: props.widget.parameters[1].slug === 'lines' && props.widget.parameters[0].value === 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
							filter={filter}
						/>
					</Source>
					<Source
						type="vector"
						url={`${MAP_URL}/chartis/v2/layer/${applySegmentation()}/mvt/geo/?codes_lignes__overlaps=${props.widget.parameters[1].value.replaceAll(/\s/g, '')}&classe_strategique__in=${props.widget.parameters[0].value.replace(/ *\([^)]*\) */g, "")}`}
						source-layer={OBJECTS_LAYERS.tivGeo}
					>
						<Layer
							{...lineLayerClass}
							layout={{ visibility: props.widget.parameters[1].slug === 'lines' && props.widget.parameters[0].value !== 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
						/>
						<Layer
							{...lineLayerHighlightedClass}
							layout={{ visibility: props.widget.parameters[1].slug === 'lines' && props.widget.parameters[0].value !== 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
							filter={filter}
						/>
					</Source>
					<Source
						type="vector"
						url={`${MAP_URL}/chartis/v2/layer/${applySegmentation()}/mvt/geo/?classe_strategique__in=${props.widget.parameters[0].value.replace(/ *\([^)]*\) */g, "")}`}
						source-layer={OBJECTS_LAYERS.tivGeo}
					>
						<Layer
							{...lineLayerClassOnly}
							layout={{ visibility: props.widget.parameters[1].slug !== 'lines' && props.widget.parameters[0].value !== 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
						/>
						<Layer
							{...lineLayerHighlightedClassOnly}
							layout={{ visibility: props.widget.parameters[1].slug !== 'lines' && props.widget.parameters[0].value !== 'Tous' ? "visible" : "none" }}
							source-layer={applySegmentation()}
							filter={filter}
						/>
					</Source>
					{showPrs && (<PRView showPrsLayer={showPrsLayer} />)}

					{showPopup && (<MapPopup
						longitude={featureInfoHover?.lngLat[0]}
						latitude={featureInfoHover?.lngLat[1]}
						closeButton={true}
						captureScroll={true}
						onClose={() => togglePopup(false)}
						className="mapboxgl-hover-custom-popup"
					>{popupContent()}</MapPopup>)}
				</ReactMapGL>
				<div className='legend-widgetmap flex-column'>
					{props.widget.parameters.map(param => (
						<div className="d-flex flex-wrap" key={param.name}>
							<div className="font-weight-bold text-capitalize">{param.name}:</div>
							<div className="text-capitalize ml-2">{param.value}</div>
						</div>
					))}
				</div>
				{displayColorLegend(props.widget.parameters, widgetData, color)}
				<div className="delete" id="delete">
					<button
						type="button"
						className="btn-rounded btn-rounded-white"
						onClick={() => deleteWidgetFromDashboard()}
					>
						<i className="icons-close" aria-hidden="true" />
					</button>
				</div>
			</div>
			<div className="toggles" id="toggles">
				<button
					type="button"
					className={`mt-4 btn btn-sm ml-3 ${showPrsLayer === "visible" ? "btn-primary" : "btn-light"
						}`}
					onClick={togglePRs}
				>
					PR
				</button>
				{props.widget.parameters[0].value !== 'Tous' || props.widget.parameters[1].slug === 'lines' ? <button
					type="button"
					className={`mt-4 btn btn-sm ml-3 ${showSegmentsLayer === "visible" ? "btn-primary" : "btn-light"
						}`}
					onClick={toggleSegments}
				>
					SEGMENTS
				</button> : undefined}

			</div>
			{loadingError && (
				<div className="dataLoader" id="dataLoader">
					<div className="mx-5">Erreur lors du chargement des données, tentez de rafraîchir votre page ou supprimez ce widget</div>
				</div>
			)}
		</>
	)
}

export default MapWidget
