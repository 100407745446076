/* eslint-disable */

import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import { prMainLayer } from 'common/Map/Layers/geographiclayers.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'

function PRView(props) {
  return (
    <Source
      type="vector"
      url={`${MAP_URL}/chartis/v2/layer/pm_sig_pr/mvt/geo/`}
      source-layer={OBJECTS_LAYERS.pmSigPr}
    >
      <Layer
        {...prMainLayer}
        layout={{ visibility: props.showPrsLayer }}
        source-layer={OBJECTS_LAYERS.pmSigPr}
				id="prMainLayer"
      />
      
    </Source>
  )
}

export default PRView
